main.careerPage {
  overflow: hidden;
}

.aboutWorking > h2 {
  text-align: center;
  width: 100%;
}
.jobs > h2 {
  text-align: center;
  width: 100%;
}

.careerHeader {
  width: 100vw;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-top: 70px; */
}

.careerHeader > img {
  width: 900px;
  object-fit: contain;
  height: 600px;
  margin-top: 200px;
  margin-right: -20px;
}

img.carrer_top_bg_right {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  z-index: -30;
}

img.carrer_top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 700px;
  z-index: -20;
}

@media (max-width: 768px) {
  img.carrer_top_bg_right {
    display: none;
  }

  img.carrer_top_bg {
    display: none;
  }
}

.careerHeader .careerHeader_center {
  margin-top: 150px;
  margin-left: 200px;
  color: black;
  width: 70%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.careerHeader .careerHeader_center > h2 {
  font-size: 45px;
  font-weight: bold;
}

.careerHeader .careerHeader_center > button {
  background-color: #605edf;
  padding: 15px 60px;
  border-radius: 50px;
  transition: 0.2s ease-in all;
  color: white;
  font-weight: 900;
  font-size: 26px;
}

.careerHeader .careerHeader_center > button:hover {
  background-color: white;
  color: black;
  transform: scale(1.02);
  border: 2px solid #605edf;
}

@media (max-width: 768px) {
  .careerHeader .careerHeader_center {
    background-color: white;
    width: 90%;
    margin: 0 0;
  }
  .careerHeader .careerHeader_center > h2 {
    font-size: 25px;
    font-weight: 600;
    margin-left: 10px;
  }
  .careerHeader .careerHeader_center > button {
    background-color: #605edf;
    padding: 5px 20px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    font-size: 15px;
    margin-top: 10px;
  }
  .careerHeader > img {
    width: 200px;
    object-fit: contain;
    height: 250px;
    margin-top: 0;
    margin-right: -20px;
  }
  .careerHeader {
    height: 250px;
    margin-top: 40px;
  }
}

#heading {
  font-size: 40px;
  font-weight: bold;
}

.aboutWorking {
  margin: 200px 0 100px 0;
}

.aboutWorking > div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 2fr));
  place-items: center;
  width: 70%;
  gap: 40px;
  margin: 40px auto;
}

.aboutWorking img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 13px;
}
.aboutWorking h3 {
  font-size: large;
  font-weight: 600;
}

.aboutWorking p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.aboutWorking .aboutWorking_Container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

@media (max-width: 768px) {
  #heading {
    font-size: 25px;
    font-weight: 600;
  }
  .aboutWorking {
    margin: 20px 20px;
  }
  .aboutWorking > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 2fr));
    place-items: center;
    width: 100%;
    gap: 40px 20px;
    margin: 40px auto;
  }
  .aboutWorking .aboutWorking_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .aboutWorking .aboutWorking_Container {
    height: 200px;
  }

  .aboutWorking h3 {
    font-size: smaller;
    text-align: center;
    font-weight: 500;
  }

  .aboutWorking p {
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    margin-top: 4px;
  }
  .aboutWorking img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 0 5px;
  }
}

.officeImages {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 2fr));
  margin: 100px 0;
  background-color: red;
}

.officeImages img {
  height: 300px;
  width: 100%;
}

.jobs .jobs_heading {
  width: 60%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.jobs .jobs_heading h4 {
  font-size: 20px;
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
}

.jobs .jobs_heading h4.jobs_active {
  text-decoration: underline;
  color: red;
}

.jobs .job_position {
  width: 60%;
  margin: auto;
}

.jobs .job_position h4 {
  font-size: 15px;
  font-weight: 600;
}
.jobs .job_position p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.job_position .job_positionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job_positionContainer p {
  max-width: 400px;
}

.job_positionContainer button {
  background-color: rgb(0, 0, 255, 0.8);
  padding: 10px 30px;
  color: white;
  border-radius: 30px;
  font-weight: 800;
  transition: 0.2s ease-in-out all;
}

.job_positionContainer button:hover {
  color: rgb(0, 0, 255, 0.8);
  background-color: white;
}

@media (max-width: 768px) {
  .jobs .job_position {
    width: 90%;
    margin: auto;
  }
}

.model_container {
  width: calc(100vw - 300px);
  height: calc(100vh - 200px);
  background-color: white;
}

.model_container input {
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 0;
  padding: 6px 10px;
  width: 96%;
  margin: 20px 20px;
}

.modal_top_heading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px auto;
  border-bottom: 1px solid black;
  width: 80%;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .modal_top_heading {
    width: 100%;
  }
}

.modal_top_heading button {
  font-size: 20px;
  font-weight: 500;
  margin-right: 30px;
  cursor: pointer;
}

.modal_top_heading button.modal_active {
  font-weight: 700;
  color: blue;
}

.modal_filling {
  width: 80%;
  margin: auto;
}

.modal_filling > .modal_filling_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.modal_submit {
  background-color: rgb(0, 0, 255, 0.8);
  padding: 10px 40px;
  color: white;
  border-radius: 30px;
  font-weight: 800;
  transition: 0.2s ease-in-out all;
  margin: auto;
  display: block;
  margin-top: 20px;
}

button.modal_submit:disabled {
  background-color: gray;
}

.modal_filling .modal_filling_relocate {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modal_filling .modal_filling_relocate > h3 {
  display: inline;
}
.modal_filling .modal_filling_relocate > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea#modal_message {
  width: 100%;
  border: 2px dotted gray;
  padding: 10px;
  border-radius: 20px;
  outline: none;
}

@media (max-width: 768px) {
  .modal_filling .modal_filling_relocate {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
  }
  .model_container {
    width: calc(100vw - 50px);
    height: calc(100vh - 200px);
    border-radius: 100px;
  }
  .modal_top_heading {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
  }
  .modal_top_heading button {
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
  }
  .modal_filling .modal_filling_relocate > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.job_position h4.job_position_heading {
  display: inline;
  font-size: 20px;
}

.job_position div.job_position_heading_container {
  margin-left: 30px;
}

p.job_position_bottom {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 30px;
}

.job_position_bottom_detail {
  margin: 10px 30px;
}

.job_position_bottom_detail h5 {
  font-size: 15px;
  font-weight: 600;
}

.job_position_bottom_container button {
  margin: 20px 0;
  background-color: blue;
  padding: 10px 25px;
  color: white;
  border-radius: 30px;
  font-weight: 600;
}

.job_position_bottom_container button:hover {
  background-color: white;
  color: blue;
  border: 2px solid blue;
}

.modal_filling_relocate span {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .modal_filling_relocate span {
    margin: 0 0;
  }
  .modal_filling_relocate h3 {
    font-size: 15px;
    width: 90%;
  }

  div#career_model {
    padding: 0 !important;
  }
  div > .ReactModal__Content .ReactModal__Content--after-open {
    padding: 0 !important;
  }

  form.modal_filling {
    width: 90%;
    padding: 20px 0;
  }
}
