.brain_container{
    display: grid;
    grid-template-areas: "brainTitle"
                        "brainContainer";
    place-items: center;
    grid-template-columns: 1fr ;
    grid-template-rows: 0.3fr 1fr ;
    height: fit-content;
    margin-top: 60px;
}
.brain_container > h2 {
    grid-area: brainTitle;
    height: 100%;
    width: 100%;
    font-size: 25px;
    font-weight: bold;
}
.brain_container > div{
    grid-area: brainContainer;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:  1fr;
    grid-gap: 0 10px;
    overflow-x: scroll;

}
.brain_container > div::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
    display: none !important;
    scrollbar-width: none !important;
  }
.brain_container > div img{
    max-width: 90%;
    max-height: 90%;
    border-radius: 25px;
    object-fit: cover;
    margin: auto;
}
.brain_container > div h4{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
    color: rgba(255,255,255,0.7);
}

@media (max-width:1024px) {
    .brain_container{
        display: grid;
        grid-template-areas: "brainTitle"
                            "brainContainer";
        place-items: center;
        grid-template-columns: 1fr ;
        grid-template-rows: 40px 150px ;
        height: fit-content;
        margin-top: 40px;
    }
    .brain_container > div{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 100px;
    }
    .brain_container > div h4{
        font-size: 10px;
       text-overflow: clip;
       white-space: nowrap;
       margin-top: 10px;
    }
    .brain_container > div img{
        max-width: 100px;
    max-height: 100px;
        border-radius: 10px;
        box-shadow:none;
    }
    .brain_container > h2 {
        font-size: 18px;
    }
    .brain_container > div{
        grid-gap: 0 10px;
    }
}