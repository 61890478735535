.glass {
  backdrop-filter: blur(100px);
  background-color: rgba(123, 141, 156, 0.121);
}

.glass_brightness {
  backdrop-filter: blur(5px);
  background-color: rgba(116, 122, 146, 0.111);
}

