.careerLogin {
  width: 100vw;
  min-height: 100vh;
  background-color: #0f172a11;
}

.careerLogin_Container_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.careerLogin > h2 {
  text-align: center;
  font-weight: 700;
  padding: 60px 0;
  font-size: 30px;
}

.careerLogin_Container_single {
  border: 2px solid grey;
  border-radius: 20px;
  background-color: whitesmoke;
  padding: 10px 25px;
  margin: 40px 70px;
}
.careerLogin_Container_single:hover {
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(223, 223, 223);
}

.careerLogin_Container_single img {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  cursor: pointer;
}

h2.careerLogin {
  font-size: 30px;
  text-align: center;
  margin: 50px 0;
  color: azure;
}

h4.careerLogin_single {
  font-size: 30px;
  font-weight: bold;
}

.careerLogin_Container_single .experince {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

p.careerLogin_single_position {
  font-weight: 600;
}
p.careerLogin_single_position_relocate {
  font-size: 12px;
  font-weight: 400;
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.search-bar input {
  width: 50%;
  min-width: 300px;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 16px;
  border: 2px solid grey;
  outline: none;
}

.search-bar [list]::-webkit-list-button,
.search-bar [list]::-webkit-calendar-picker-indicator {
  display: none !important;
}
.pdf_image {
  min-width: 50px;
  min-height: 50px;
  margin: 0px 10px;
}

.date {
  margin-top: 10px;
}
