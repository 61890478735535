::-webkit-scrollbar {
  display: none;
}

.radio-container {
  min-height: 100vh;
  background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
}

.search-channel {
  min-height: 100vh;
  background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
}
.country-modal {
  min-height: 100vh;
  background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
}
.card-container {
}

.footer {
  display: none;
}
.footer-player {
  width: 500px;
  height: 100px;
  z-index: 20;
  background-color: red;
  position: relative;
  top: 80;
}
.hide-player {
  display: none;
}

.greeting {
  font-size: 6vh;
}

.main-radio {
  .input-search {
    width: 80vw !important;
    height: 15vw;
    background-color: transparent;
    border: 1px solid #5266b2;
    padding: 4vw;
    font-size: 5vw;
    color: white;
    border-radius: 2vw;
    margin-bottom: 5vh;
  }
  .input-search::placeholder {
    color: #5266b2;
  }
  .input-search:focus {
    outline: none;
  }
}

.active {
  color: white !important;
}

.footer-player {
  position: absolute;
  top: 75vh;
  left: 5%;
  width: 90vw;
  height: 8vh;

  padding-left: 2vw;

  background-color: #272e45;
  border-radius: 6vw;
  .logo {
    width: 10vw;
    height: 10vw;
    object-fit: cover;
    background-color: white;
    border-radius: 2.5vw;
    padding: 0 !important;
  }
  h1 {
    font-size: 4vw;
    font-weight: 400;
    color: white;
  }
  .play-pause {
    width: 6vw;
    height: 6vw;
  }
}

.ChannelModal {
  .input-search {
    width: 80vw !important;
    background-color: transparent;
    border: 1px solid #5266b2;
    padding: 4vw;

    font-size: 5vw;
    color: white;
    border-radius: 2vw;
  }
  .input-search::placeholder {
    color: #5266b2;
  }
  .input-search:focus {
    outline: none;
  }
}
.search-country {
  padding-top: 8vh !important;
  padding-bottom: 5vh;
  position: sticky;
  top: 2vh;
  background-color: #232f58;

  .input-search {
    width: 80vw !important;
    background-color: transparent;
    border: 1px solid #5266b2;
    padding: 4vw;

    font-size: 5vw;
    color: white;
    border-radius: 2vw;
  }
  .input-search::placeholder {
    color: #5266b2;
  }
  .input-search:focus {
    outline: none;
  }
}
.modal-image {
  img {
    height: 25vw;
    width: 25vw;
    border-radius: 4vw;
    margin-top: 8vw;
    // box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
    // -webkit-box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
    // -moz-box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
  }
}
.modal-play-pause {
  width: 6vw;
  height: 6vw;
}
.modal-prev-next {
  width: 6vw;
  height: 6vw;
}
.modal-animation {
  position: absolute;
  height: 10%;
  width: 50%;
  top: 13.8vw;
}
.bottom-player {
  .modal-play-pause {
    width: 5vw;
    height: 5vw;
  }
  .modal-prev-next {
    width: 5vw;
    height: 5vw;
  }
}
.share {
  position: absolute;
  top: 0;
  right: 5vw;
}

@media (min-width: 769px) {
  .radio-container {
    min-height: 100vh;
    background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
  }

  .search-channel {
    min-height: 100vh;
    background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
  }
  .country-modal {
    min-height: 100vh;
    background: linear-gradient(0deg, #5266b2 20%, #232f58 80%);
  }
  .card-container {
  }

  .footer {
    display: none;
  }
  .footer-player {
    width: 500px;
    height: 100px;
    z-index: 20;
    background-color: red;
    position: relative;
    top: 80;
  }
  .hide-player {
    display: none;
  }

  .greeting {
    margin-top: 5vw;
    font-size: 5vw;
    br {
      display: none;
    }
  }

  .main-radio {
    .input-search {
      width: 40vw !important;
      height: 3vw !important;
      background-color: transparent;
      border: 1px solid #5266b2;
      padding: 2vw !important;
      color: white;
      border-radius: 1vw;
      margin-bottom: 1vh !important;
      font-size: 2vw;
    }
    .input-search::placeholder {
      color: #5266b2;
    }
    .input-search:focus {
      outline: none;
    }
  }

  .active {
    color: white !important;
    font-size: 2.3vw;
  }

  .footer-player {
    position: absolute;
    top: 85vh;
    left: 30%;
    width: 40vw;
    height: 9vh;
    padding-left: 2vw;
    background-color: #272e45;
    border-radius: 1vw;

    .logo {
      width: 3vw;
      height: 3vw;
      object-fit: cover;
      background-color: white;
      border-radius: 2.5vw;
      padding: 0 !important;
    }
    h1 {
      font-size: 1.5vw;
      font-weight: 400;
      color: white;
    }
  }

  .ChannelModal {
    .input-search {
      width: 60vw !important;
      height: 10vh;
      background-color: transparent;
      border: 1px solid #5266b2;
      padding: 2vw;
      font-size: 2vw;
      color: white;
      border-radius: 1vw;
    }
    .input-search::placeholder {
      color: #5266b2;
    }
    .input-search:focus {
      outline: none;
    }
  }
  .search-country {
    padding-top: 15vh;
    padding-bottom: 5vh;
    align-self: center;
    position: sticky;
    top: 8vh;
    background-color: #232f58;
    .input-search {
      width: 60vw !important;
      background-color: transparent;
      border: 1px solid #5266b2;
      padding: 2vh;
      font-size: 2vw;
      color: white;
      border-radius: 1vw;
    }
    .input-search::placeholder {
      color: #5266b2;
    }
    .input-search:focus {
      outline: none;
    }
  }
  .modal-image {
    img {
      height: 10vw;
      width: 10vw;
      border-radius: 4vw;
      margin-top: 4vw;
      // box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
      // -webkit-box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
      // -moz-box-shadow: 0px 0px 391px 30px rgba(7, 7, 7, 0.75) inset;
    }
  }
  .modal-play-pause {
    width: 4vw;
    height: 4vw;
  }
  .modal-prev-next {
    width: 4vw;
    height: 4vw;
  }
  .modal-animation {
    position: absolute;
    height: 10%;
    width: 50%;
    top: 13.8vw;
  }
  .bottom-player {
    .modal-play-pause {
      width: 1.5vw;
      height: 1.5vw;
    }
    .modal-prev-next {
      width: 1.5vw;
      height: 1.5vw;
    }
  }
}
