.casual_container {
  display: grid;
  grid-template-areas:
    "casualTitle"
    "casualContainer";
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 1fr;
  height: fit-content;
  margin-top: 60px;
}
.casual_container > h2 {
  grid-area: casualTitle;

  width: 100%;
  font-size: 25px;
  font-weight: bold;
}
.casual_container > div {
  grid-area: casualContainer;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px 10px;
  overflow-x: scroll;
}

.casual_container > div::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
  display: none !important;
  scrollbar-width: none !important;
}

.casual_container > div img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 25px;
  object-fit: cover;
  margin: auto;
}
.casual_container > div h4 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 1024px) {
  .casual_container {
    display: grid;
    grid-template-areas:
      "casualTitle"
      "casualContainer";
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 0.35fr 1fr;
    height: fit-content;
    margin-top: 30px;
  }
  .casual_container > div {
    grid-area: casualContainer;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.5fr;
    grid-gap: 10px 10px;
  }
  .casual_container > div h4 {
    font-size: 10px;
    text-overflow: clip;
    white-space: nowrap;
    margin-top: 10px;
    text-align: center;
  }
  .casual_container > div img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 15px;
    box-shadow: none;
  }
  .casual_container > h2 {
    font-size: 18px;
  }
}
