.playIt_container {
  max-height: 100vh !important;
  background-image: url("/public/images/gamesScreen/new/Group 7528.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
  overflow-y: auto !important;
}

.main-games {
  padding: 10px 200px 100px;
}

.playIt_scroll::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
  display: none !important;
  scrollbar-width: none !important;
}

img.playIt_container_logo {
  width: 130px;
  height: 50px;
  object-fit: contain;
}
.playIt_container_top {
  display: grid;
  grid-template-areas: "top_first top_second";
  grid-template-columns: 3fr 1fr;
  height: 300px;
  margin-top: 20px;
  border-radius: 20px;
  place-items: center;
  background-image: url("https://images.pexels.com/photos/262438/pexels-photo-262438.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1204px) {
  .playIt_container_top {
    grid-template-columns: 3fr 2fr;
    height: 150px;
  }
  .playIt_container {
    padding: 10px 5px 50px 2px;
    color: white;
  }
}

.playIt_container_top_left {
  grid-area: top_first;
}
.playIt_container_top_right {
  grid-area: top_second;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-areas:
    "top_second_img "
    "top_second_name"
    "top_second_button";
  grid-template-rows: 3fr 0.4fr 1fr;
  place-items: center;
}

.playIt_container_top_right > img {
  grid-area: top_second_img;
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
.playIt_container_top_right > h3 {
  grid-area: top_second_name;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.playIt_container_top_right > button {
  grid-area: top_second_button;
  width: 50%;
  height: 70%;
  margin: auto;
  background-color: white;
  border-radius: 20px;
  color: black;
  font-weight: bold;
  font-size: 25px;
}

.playIt_container_recom {
  display: grid;
  grid-template-areas:
    " re_heading re_heading re_heading re_heading re_heading"
    " re_one re_two re_three re_four re_five";
  height: min-content;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 10px;
  grid-template-rows: 0.59fr 1fr;
  grid-gap: 0 15px;

  height: fit-content;
  place-items: center;
  overflow-x: hidden;
}
.playIt_container_recom::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
  display: none !important;
  scrollbar-width: none !important;
}
h2.heading_recom {
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: bold;
}
@media (max-width: 1024px) {
  h2.heading_recom {
    font-size: 18px;
  }
  .playIt_container_recom {
    display: grid;
    grid-template-areas:
      ". re_heading re_heading re_heading re_heading re_heading"
      ". re_one re_two re_three re_four re_five";
    height: 160px;
    grid-template-columns: 10px 100px 100px 100px 100px 100px 50px;
    grid-template-rows: 1fr;
    grid-gap: 0 10px;
    overflow-x: hidden;
  }
  .playIt_container_recom::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
    display: none !important;
    scrollbar-width: none !important;
  }
  .main-games {
    padding: 5vw 10vw 3vw 10vw;
  }
}

.playIt_container_recom_one {
  grid-area: re_one;
  width: 100%;
  height: 100%;
}
.playIt_container_recom_two {
  grid-area: re_two;
  width: 100%;
  height: 100%;
}
.playIt_container_recom_three {
  grid-area: re_three;
  width: 100%;
  height: 100%;
}
.playIt_container_recom_four {
  grid-area: re_four;
  width: 100%;
  height: 100%;
}
.playIt_container_recom_five {
  grid-area: re_five;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .main-games {
    padding: 0;
    padding-bottom: 10px;
  }
  .playIt_container_recom {
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    padding-top: 4vh;
    padding-left: 2vh;
  }
  .playIt_container_recom::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
    display: none !important;
    scrollbar-width: none !important;
  }

  .playIt_container_recom_one {
    grid-area: re_one;
    width: 30%;
    height: 30%;
  }
  .playIt_container_recom_two {
    grid-area: re_two;
    width: 30%;
    height: 30%;
  }
  .playIt_container_recom_three {
    grid-area: re_three;
    width: 30%;
    height: 30%;
  }
  .playIt_container_recom_four {
    grid-area: re_four;
    width: 30%;
    height: 30%;
  }
  .playIt_container_recom_five {
    grid-area: re_five;
    width: 30%;
    height: 30%;
  }

  .adsbygoogle {
    margin-top: 8vh;
  }
}
