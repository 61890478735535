@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

body {
  font-family: "Questrial", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}

.main_container_slider {
  height: 800px;
}

.slick-dots {
  position: absolute;
  bottom: 20px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.main_container_slider .slick-slider {
  max-height: 1000px;
  overflow: hidden !important;
  min-width: 100vw;
}

.main_container_slider .slick-track {
  display: flex !important;
  margin: 0 !important;
  height: 900px;
}

.main_container_slider .slick-slide {
  height: inherit !important;
  overflow: hidden !important;
}

.main_container_slider .slick-list {
  padding: 0 0 0 0 !important;
}

.slider_1 {
  position: relative;
}

.main_container_slider .slick-dots {
  height: 50px;
}

.main_container_slider .slick-dots li.slick-active > button {
  height: 40px;
  color: black !important;
}
.main_container_slider .slick-dots li > button {
  height: 40px;
  color: white !important;
}

.main_container_slider .slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: gray;
}

.main_container_slider .slick-dots li.slick-active button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: rgb(106, 53, 53);
}

.slider_1_rocks_logo {
  position: absolute;
  left: 100px;
  top: 150px;
  text-align: right;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  z-index: 40;
}
.slider_1_rocks_logo h2 {
  color: #ea2924;
  font-size: 60px;
  font-weight: 900;
}
.slider_1_rocks_logo h3 {
  font-size: 32px;
  font-weight: 900;
}
.slider_1_rocks_logo p.slider_1_rocks_logo_text {
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  text-align: left;
}
.slider_1_rocks_logo > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_1_rocks_logo img.google_play {
  width: calc(2vw + 200px) !important;
  margin-right: 0 !important;
  object-fit: contain;
  height: calc(2vw + 70px) !important;
  object-position: right;
}
.slider_1_rocks_logo img {
  /* width: 40px !important; */
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
}
.slider_1_downloads {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_1_downloads button {
  background-color: white;
  border: 1px solid black;
  height: 63px;
  width: 180px;
  border-radius: 10px;
  margin-right: 20px;

  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.3);
}

.slider_1_downloads p,
.slider_1_downloads h3 {
  text-align: center;
  font-size: 20px;
}
.slider_1_downloads h3 {
  font-size: 20px;
}

.slider_1 > img.slider_1_main_image {
  position: absolute;
  width: 550px;
  height: 690px;
  object-fit: contain;
  object-position: center;
  right: 100px;
  z-index: 10;
  top: 0;
}

.slider_2 {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 100px;
  background-color: #eeebfe;
  /* z-index: -10; */
}

.slider_2 .slider_2_download {
  display: none !important;
}

.slider_2 img.slider_2_main_image {
  width: 100%;
  height: 560px;
  object-fit: contain;
  display: block;
  margin: auto;
  object-position: right;
  margin-top: 30px;
  margin-bottom: 40px;
}
.slider_2 div.slider_2_icon > img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
}
.slider_2 div.slider_2_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.slider_2 h4 {
  text-align: center;
  font-size: clamp(1rem, 3vw, 2rem);
  font-weight: clamp(300, 3vw, 400);
  letter-spacing: 1px;
}
.slider_2 div.slider_2_icon > h2 {
  font-size: 60px;
  color: rgb(19, 9, 57);
  font-weight: 900;
  letter-spacing: 6px;
}

.slider_2 .slider_2_download {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_2 .slider_2_download img {
  width: 150px;
}

.slider_2 .slider_2_download .first {
  background-color: black;
  width: 140px;
  height: 40px;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.slider_2 .slider_2_download .first h5 {
  font-size: 25px;
  font-weight: 400;
}

.slider_2 .slider_2_download .first p {
  font-size: 13px;
  margin-left: 10px;
}

.slider_2_download_desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
}

.slider_2_download_desktop > .first {
  background-color: #3b3f60;
  color: white;
  padding: 14px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider_2_download_desktop > .first h5 {
  font-weight: 600;
  font-size: 20px;
}

.slider_2_download_desktop img.google {
  width: calc(2vw + 230px) !important;
  margin-right: 0 !important;
  object-fit: contain;
  object-position: left !important;
  height: calc(2vw + 50px) !important;
  object-position: right;
}

.slider_3 {
  color: white;
  position: relative;
  /* overflow-x: hidden; */
}

.slider_3_banner {
  position: absolute;
  background: rgba(14, 14, 14, 0.39);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.7px);
  -webkit-backdrop-filter: blur(13.7px);
  border: 1px solid rgba(14, 14, 14, 0.3);
  background-image: linear-gradient(
    20deg,
    hsl(0deg 0% 0%) 0%,
    hsl(276deg 22% 11%) 19%,
    hsl(276deg 56% 16%) 30%,
    #160a52,
    #0d26b4,
    #801989,
    #732c9c
  );

  height: 900px;
  width: 110vw;
  z-index: -100;
  top: -100px;
  left: -20px;
}

.slider_3_desktop {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
  padding: 18vh 10vw;
}

.slider_3_desktop > img.slider_1_main_image {
  width: 40vw;
  object-fit: contain;
  margin-left: 10vw;
}

/* @media (max-width: 1201px) {
  .slider_3_desktop > img.slider_1_main_image {
    width: 550px;
  }
  .slider_3_desktop {
    padding: 100px 20px;
  }
} */

.slider_3_desktop .slider_3_desktop_text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 8vh;
}

.slider_3_desktop .slider_3_desktop_text p {
  text-align: left;
}

.slider_3_desktop .slider_3_desktop_text h2 {
  text-align: left;
}
.slider_3_desktop .slider_3_desktop_text h4 {
  text-align: left;
  font-size: 1.5vw;
  font-weight: 400;
  margin: 20px 0;
}

.slider_3_desktop .slider_3_desktop_text a > img.google {
  width: calc(2vw + 180px) !important;
  margin-right: 0 !important;
  object-fit: cover;
  height: calc(2vw + 28px) !important;
  object-position: left;
  margin: 4vh -1vw;
  margin-bottom: 1vh;
}

.slider_3_mobile {
  display: none !important;
}

.slider_3 img {
  display: block;
  margin: auto;
  object-fit: contain;
  object-position: center;
  width: 500px;
}

.slider_3_weCreate {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_3 h1.everything {
  text-align: left;
  font-weight: 400;
  /* font-size: 70px !important; */
  /* text-shadow: 0.1px 0.1px #ffffff !important; */
  margin-bottom: 25px;
  font-size: 2.8vmax !important;
  margin: auto;
  color: transparent white !important;
  background-clip: text !important;
  background: -webkit-linear-gradient(#eee 40%, #333) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
  line-height: 8vh;
  font-family: "Questrial", sans-serif;
}

h2.create {
  text-align: center;
  font-size: 2.8vw;

  background: #ff0058;
  background: transparent linear-gradient(120deg, #ff143f 0%, #3e28ff 100%) 0%
    0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;

  font-family: "Questrial", sans-serif;
}
.create span {
  text-align: left;
  font-weight: 400;
  /* font-size: 70px !important; */
  /* text-shadow: 0.1px 0.1px #ffffff !important; */
  margin-bottom: 40px;
  font-size: 2.8vw !important;
  margin: auto;
  color: white !important;
  background-clip: text !important;
  background: -webkit-linear-gradient(#eee 40%, #333) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  font-family: "Questrial", sans-serif;
}

.slider_3 h4 span {
  color: red;
  font-size: 1.5vw;
  font-weight: 500;
}

.slider_3_powered {
  font-size: 10px;
  text-align: center;
  margin-top: 6vh;
}
.slider_3_powered h3 {
  font-size: 18px;
  color: #a3003a;
  text-align: center;
}

@media (max-width: 1200px) {
  .slider_2 .slider_2_download .first h5 {
    font-size: 20px;
    font-weight: bold;
    font-weight: 400;
  }

  .slider_2 div.slider_2_icon > img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 10px;
  }

  .slider_2 div.slider_2_icon > h2 {
    font-size: 32px;
    color: rgb(19, 9, 57);
    font-weight: 500;
    letter-spacing: 5px;
  }

  .slider_1_rocks_logo h3 {
    font-size: 28px;
    font-weight: 500;
  }
  .slider_3_banner {
    top: -10px;
    left: -3px;
  }
  .main_container_slider .slick-dots {
    /* background-color: #ea2924; */
    height: 40px;
    background-color: black;
    margin-top: -100px !important;
    z-index: 40;
    bottom: 70px;
  }
  .gameHeight {
    display: none !important;
  }
  .slider_3_mobile {
    display: block !important;
  }
  .slider_3_desktop {
    display: none !important;
  }
  .slider_3 img {
    height: clamp(150px, 40%, 350px) !important;
    display: block;
    margin: auto;
    width: clamp(350px, 40%, 550px);
    position: initial;
    margin-top: 40px;
  }
  .slider_3 h4 {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    width: 90%;
    margin: 20px auto;
  }
  .slider_2 {
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
  }

  .slider_2 .slider_2_download {
    display: flex !important;
  }
  .slider_2_download_desktop {
    display: none !important;
  }
  .slider_1_downloads button {
    background-color: white;
    border: 0.5px solid black;
    width: calc(2vw + 110px) !important;
    height: calc(2vw + 28px) !important;
    border-radius: 7px;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 0.3);
  }
  .slider_1_rocks_logo img.google_play {
    width: calc(2vw + 120px) !important;
    height: calc(2vw + 50px) !important;
  }

  .slider_1_downloads p,
  .slider_1_downloads h3 {
    text-align: center;
    font-size: 8px;
  }
  .slider_1_downloads h3 {
    font-size: 10px;
  }
  .slider_1_downloads {
    margin-top: 2px;
  }
}

@media (max-width: 1024px) {
  .main_container_slider {
    height: 800px;
    padding-top: 0;
  }

  .slider_2 img.slider_2_main_image {
    width: 270px;
    height: 360px;
    object-fit: contain;
    display: block;
    margin: auto;
    object-position: top;
    margin-top: 30px;
  }
  .slider_1 > img.slider_1_main_image {
    position: absolute;
    width: 350px;
    height: 600px;
    object-fit: contain;
    object-position: bottom;
    left: 10px;
    z-index: 10;
    top: 0;
  }
  .slider_1_rocks_logo {
    position: absolute;
    left: -10px;
    top: 20px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 30;
  }
  .slider_1_rocks_logo h2 {
    color: #ea2924;
    font-size: 35px;
    font-weight: 600;
  }

  .slider_1_rocks_logo p.slider_1_rocks_logo_text {
    font-size: 13px;
    font-weight: 400;
    text-align: right;
  }
  .slider_2 > div.slider_2_icon > h2 {
    font-size: 35px;
    color: rgb(19, 9, 57);
    font-weight: 500;
  }
  /* christmas changes padding-top:60  */
  .slider_3 {
    padding-top: 100px !important;
  }
  .slider_2 {
    height: 690px;
    padding-top: 100px !important;
    padding-bottom: 50px;
  }
  /* christmas changes margin top was 40 */
  .slider_1 {
    margin-top: 80px !important;
  }
}

.game_mobile {
  display: none;
}
.game_mobile_desktop {
  display: block;
}

@media (max-width: 1024px) {
  .game_mobile {
    display: block;
  }
  .game_mobile_desktop {
    display: none;
  }
}

img.slider_1_shape1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
}

img.slider_1_second_shape {
  position: absolute;
  right: 20px;
  bottom: -800px;
  width: 500px;
  height: 200px;
}

@media (max-width: 768px) {
  .slider_2 .slider_2_download .first h5 {
    font-size: 20px;
    font-weight: bold;
    font-weight: 400;
  }

  .everything_mobile {
    text-align: center;
    font-weight: 400;
    /* font-size: 70px !important; */
    /* text-shadow: 0.1px 0.1px #ffffff !important; */
    margin-bottom: 40px;
    font-size: 5vmax !important;
    margin: auto;
    color: transparent !important;
    background-clip: text !important;
    background: -webkit-linear-gradient(#eee 40%, #333) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  }

  .create_mobile {
    text-align: center;
    font-size: 5vmax;
    background: #cf1512;
    background: linear-gradient(to right, #cf1512 8%, #121fcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
  .create_mobile span {
    text-align: center;
    font-weight: 400;
    /* font-size: 70px !important; */
    /* text-shadow: 0.1px 0.1px #ffffff !important; */
    margin-bottom: 40px;
    font-size: 5vmax !important;
    margin: auto;
    color: white !important;
    background-clip: text !important;
    background: -webkit-linear-gradient(#eee 40%, #333) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  }

  .slider_3_mobile h4 {
    color: white;
    font-size: 4vw;
  }
  .slider_3_mobile h4 span {
    color: red;
    font-size: 4vw;
  }

  .slider_3_powered {
    font-size: 10px;
    text-align: center;
    margin-top: 9vh;
  }
  .slider_3_powered h3 {
    font-size: 18px;
    color: #a3003a;
    text-align: center;
  }

  .slider_2 div.slider_2_icon > img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 10px;
  }

  .slider_2 div.slider_2_icon > h2 {
    font-size: 32px;
    color: rgb(19, 9, 57);
    font-weight: 500;
    letter-spacing: 5px;
  }

  .slider_1_rocks_logo h3 {
    font-size: 28px;
    font-weight: 500;
  }
  .slider_3_banner {
    top: -10px;
    left: -3px;
  }
  .main_container_slider .slick-dots {
    /* background-color: #ea2924; */
    height: 40px;
    background-color: black;
    margin-top: -100px !important;
    z-index: 40;
    bottom: 70px;
  }
  .gameHeight {
    display: none !important;
  }
  .slider_3_mobile {
    display: block !important;
    margin-top: 20px !important;
  }
  .slider_3_desktop {
    display: none !important;
  }
  .slider_3 img {
    height: clamp(150px, 40%, 350px) !important;
    display: block;
    margin: auto;
    width: clamp(350px, 40%, 550px);
    position: initial;
    margin-top: 40px;
  }
  .slider_3 h4 {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    width: 90%;
    margin: 20px auto;
  }
  .slider_2 {
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
  }

  .slider_2 .slider_2_download {
    display: flex !important;
  }
  .slider_2_download_desktop {
    display: none !important;
  }
  .slider_1_downloads button {
    background-color: white;
    border: 0.5px solid black;
    width: calc(2vw + 110px) !important;
    height: calc(2vw + 28px) !important;
    border-radius: 7px;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 0.3);
  }
  .slider_1_rocks_logo img.google_play {
    width: calc(2vw + 120px) !important;
    height: calc(2vw + 50px) !important;
  }

  .slider_1_downloads p,
  .slider_1_downloads h3 {
    text-align: center;
    font-size: 8px;
  }
  .slider_1_downloads h3 {
    font-size: 10px;
  }
  .slider_1_downloads {
    margin-top: 2px;
  }
}

@media (max-width: 768px) {
  .main_container_slider {
    height: 800px;
    padding-top: 0;
  }

  .slider_2 img.slider_2_main_image {
    width: 270px;
    height: 360px;
    object-fit: contain;
    display: block;
    margin: auto;
    object-position: top;
    margin-top: 30px;
  }
  .slider_1 > img.slider_1_main_image {
    position: absolute;
    width: 350px;
    height: 600px;
    object-fit: contain;
    object-position: bottom;
    left: 10px;
    z-index: 10;
    top: 0;
  }
  .slider_1_rocks_logo {
    position: absolute;
    left: -10px;
    top: 20px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 30;
  }
  .slider_1_rocks_logo h2 {
    color: #ea2924;
    font-size: 35px;
    font-weight: 600;
  }

  .slider_1_rocks_logo p.slider_1_rocks_logo_text {
    font-size: 13px;
    font-weight: 400;
    text-align: right;
  }
  .slider_2 > div.slider_2_icon > h2 {
    font-size: 35px;
    color: rgb(19, 9, 57);
    font-weight: 500;
  }
  .slider_3 {
    padding-top: 60px !important;
  }
  .slider_3_mobile .slider_3_powered {
    margin-top: 2vh;
  }

  .slider_2 {
    height: 690px;
    padding-top: 70px !important;
    padding-bottom: 50px;
  }
  .slider_1 {
    margin-top: 60px !important;
  }
}

@media (max-width: 768px) {
  .game_mobile {
    display: block;
  }
  .game_mobile_desktop {
    display: none;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.cursor{
  position:absolute;
  top:-100px;
  left:50%;
  width: 1000px;
  height:1000px;
  border-radius: 50%;
  /* backdrop-filter: brightness(5px); */
  transform:translate(-50%,-50%);
  background: radial-gradient(circle, #2e65fe9a 0%, #000 62%);
  z-index:0;
  pointer-events:fill;
  }