.tree-nav {
  position: absolute;
  top: -5px;
  left: 10px;
  height: 80px;
}
.snowman {
  position: absolute;
  top: 10px;
  left: 500px;
  height: 50px;
}
.gift {
  position: absolute;
  top: 35px;
  left: 80px;
  height: 30px;
}
.gifts {
  position: absolute;
  top: 25px;
  right: 70px;
  height: 50px;
}

@media (max-width: 1400px) {
  .tree-nav {
    position: absolute;
    top: -5px;
    left: 10px;
    height: 80px;
  }
  .snowman {
    position: absolute;
    top: 10px;
    left: 350px;
    height: 50px;
  }
  .gift {
    position: absolute;
    top: 35px;
    left: 80px;
    height: 30px;
  }
  .gifts {
    position: absolute;
    top: 25px;
    right: 70px;
    height: 50px;
    display: none;
  }
}
@media (max-width: 1170px) {
  .tree-nav {
    position: absolute;
    top: -5px;
    left: 10px;
    height: 80px;
  }
  .snowman {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 50px;
    display: none;
  }
  .gift {
    position: absolute;
    top: 35px;
    left: 80px;
    height: 30px;
  }
  .gifts {
    position: absolute;
    top: 25px;
    right: 70px;
    height: 50px;
    display: none;
  }
}
@media (max-width: 768px) {
  .tree-nav {
    position: absolute;
    top: 30px;
    left: 0px;
    height: 40px;
  }
  .snowman {
    position: absolute;
    top: 10px;
    left: 300px;
    height: 50px;
  }
  .gift {
    position: absolute;
    top: 40px;
    left: 80px;
    height: 30px;
  }
  .gifts {
    position: absolute;
    top: 25px;
    right: 70px;
    height: 50px;
    display: none;
  }
}
