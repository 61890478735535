.gameTile {
  display: grid;
  grid-template-areas:
    ". tileImage ."
    ". tileName ."
    ". tileButton ."
    ". . .";
   height: 190px;
  grid-template-rows: 4fr 0.1fr 1fr 0.1fr;
  grid-template-columns: 0.8fr 3fr 0.8fr;
  place-content: center;
  border-radius: 30px;
  backdrop-filter: blur(0.05px);
  -webkit-backdrop-filter: blur(0.05px);
}
.gameTile > img {
  grid-area: tileImage;
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin: -20px auto 0;
  border-radius: 20px;
 
}
.gameTile > h4 {
  font-size: 15px;
  font-weight: bold;
  grid-area: tileName;
  height: 100%;
  width: 100%;
  text-align: center;
 
  color: rgba(255, 255, 255, 0.7);
}
.gameTile > button {
  grid-area: tileButton;
  height: 100%;
  width: 80%;
  background-color: white;
  color: black;
  margin: auto;
  border-radius: 20px;
  font-weight: bold;
  margin-top: 4px;
  background-image: linear-gradient(
    to bottom,
    white,
    rgba(0, 0, 0, 0.1),
    white
  );
  transition: all 0.1s ease-in;
}
.gameTile > button:hover {
  /* box-shadow: 0px 0px  20px black; */
  color: white;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
}

@media (max-width:1024px) {
  .gameTile {
    grid-template-areas:
      ". tileImage ."
      ". tileName ."
      ". . .";
    grid-template-rows: 3fr 0.9fr 0.5fr ;
    grid-template-columns: 0.1fr 2fr 0.1fr;
    height: 100px;
    place-content: center;
    border-radius: 20px;
    backdrop-filter: blur(0.05px);
    -webkit-backdrop-filter: blur(0.05px);
  }
  .gameTile > img {
    grid-area: tileImage;
    object-fit: cover;
    height: 100px;
    width: 100px;
    margin: -40px auto 0;
    border-radius: 20px;
  }
  .gameTile > h4 {
    font-size: 12px;
    grid-area: tileName;
    font-weight: bolder;
  }
  .gameTile > button {
    grid-area: tileButton;
    height: 100%;
    width: 90%;
    background-color: white;
    color: black;
    margin: auto;
    font-size: 10px;
    border-radius: 20px;


  }
}
