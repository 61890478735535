.action_container {
  display: grid;
  grid-template-areas:
    "actionTitle"
    "actionContainer";
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 0.30fr 1fr;
  height: fit-content;
  margin-top: 40px;
}
.action_container > h2 {
  grid-area: actionTitle;
  height: 100%;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  margin-left: 5px;
}
.action_container > div {
  grid-area: actionContainer;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr;
  grid-gap: 0 10px;
  overflow-x: scroll;
  padding-top: 10px;
}
.action_container > div::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
    display: none !important;
    scrollbar-width: none !important;
  }
.action_container > div img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 25px;
  margin: auto;
}
.action_container > div h4 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 1024px) {
  .action_container {
    display: grid;
    grid-template-areas:
      "actionTitle"
      "actionContainer";
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 30px 150px;
    margin-top: 40px;
    place-content: center;
  }
  .action_container > div {
  
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 100px;
  
  }
  .action_container > div h4 {
    font-size: 10px;
    text-overflow: clip;
    white-space: nowrap;
    margin-top: 10px;
   
  }
  .action_container > div img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 15px;
    box-shadow:none;
    margin: auto;
  }
  .action_container > h2 {
    font-size: 18px;
  }
}
