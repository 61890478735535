@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  height: 100%;
}
/* *{
  border: 1px solid red;
} */
body {
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  margin: 0;
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
h1 {
  font-size: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.slick-track {
  margin: 20px;
}

@media (max-width: 500px) {
  div.slick-track {
    margin: 3px;
  }
}

#gameCategories .slick-track {
  margin: 0px !important;
  margin-right: 20px !important;
}

#gameCategories .slick-dots {
  margin-left: -200px !important;
}

@media (max-width: 500px) {
  #gameCategories .slick-dots {
    margin-left: -20px !important;
  }
}

.footerLink {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
#blogsSlider .slick-track {
  margin: 0px !important;
}

#homeGame .slick-track {
  margin-top: 50px;
  margin-bottom: 50px;
}

#myinput {
  height: 6px;
  -webkit-appearance: none;
  background: #111;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}
#myinput::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #872aee;
  cursor: pointer;
  border: 1px solid black;
  box-shadow: -407px 0 0 400px white;
}

#blogCategorie {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#categoryDrag {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

iframe {
  border-radius: 10px;
  width: 700px !important;
  height: 500px !important;
}

.adsbygoogle iframe {
  border-radius: none !important;
  width: 100% !important;
  overflow: auto !important;
  height: 100% !important;
}
.ReactModal__Overlay {
  overflow: scroll !important;
}

@media (max-width: 700px) {
  iframe {
    border-radius: 10px;
    width: 300px !important;
    overflow: hidden;
    height: 400px !important;
  }

  .adsbygoogle iframe {
    border-radius: none !important;
    width: 100% !important;
    overflow: auto !important;
    height: 100% !important;
  }
}

.ReactModal__Content::-webkit-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  display: none !important;
}

#scrollBarNone::-webkit-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  display: none !important;
}

.ReactModal__Content ReactModal__Content--after-open::-webkit-scrollbar {
  display: none !important;
}

.rareprobTeamSlider ul.slick-dots {
  display: none !important;
}

#blogsSlider .slick-prev:before {
  display: none !important;
}

#blogsSlider .slick-next:before {
  display: none !important;
}

/* .Dropdown-arrow {
  display: none !important;
} */
