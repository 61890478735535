.stack {
    margin-bottom: 10px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* border: 1px solid red; */
  }
  
  .card {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 #00000040, 0 15px 20px 0#00000020;
    transition: transform 800ms linear;
  }
  
  .card:nth-last-child(n + 4) {
    /* --y: calc(-50% + -30px);
    transform: translate(-50%, var(--y)) scale(0.9);
    box-shadow: 0 0 1px 1px #00000003;
    background-color: #2f4b92; */
    visibility: hidden;
  }

  .card:nth-last-child(3) {
    --y: calc(-30% + -15px);
    transform: translate(-50%, var(--y)) scale(0.80);
    background-color: rgba(27, 54, 127, 0.28);
  }

  .card:nth-last-child(2) {
    --y: calc(-30%);
    transform: translate(-50%, var(--y)) scale(1);
    background-color: rgba(63, 72, 133, 0.4);
  }

  .card:nth-last-child(1) {
    --y: calc(-30% + 15px);
    transform: translate(-50%, var(--y)) scale(1.10);
  
  }

/* 1. Gentle Drift */
.floating-drift {
  animation: float-drift 8s linear infinite;
}
@keyframes float-drift {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-12px, -15px); }
  50% { transform: translate(15px, 18px); }
  75% { transform: translate(-10px, 12px); }
}

/* 2. Soft Sway */
.floating-sway {
  animation: float-sway 8s linear infinite;
}
@keyframes float-sway {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-18px, 12px); }
  50% { transform: translate(22px, -15px); }
  75% { transform: translate(-14px, 8px); }
}

/* 3. Fluid Motion */
.floating-fluid {
  animation: float-fluid 8s linear infinite;
}
@keyframes float-fluid {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-16px, -22px); }
  50% { transform: translate(20px, 18px); }
  75% { transform: translate(-12px, 14px); }
}

/* 4. Smooth Drift */
.floating-smooth {
  animation: float-smooth 8s linear infinite;
}
@keyframes float-smooth {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-14px, 16px); }
  50% { transform: translate(18px, -20px); }
  75% { transform: translate(-10px, 12px); }
}

/* 5. Gentle Wave */
.floating-wave {
  animation: float-wave 8s linear infinite;
}
@keyframes float-wave {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-20px, 10px); }
  50% { transform: translate(22px, -15px); }
  75% { transform: translate(-16px, 8px); }
}

/* 6. Subtle Drift */
.floating-subtle {
  animation: float-subtle 8s linear infinite;
}
@keyframes float-subtle {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-10px, -12px); }
  50% { transform: translate(14px, 16px); }
  75% { transform: translate(-8px, 10px); }
}

/* 7. Soft Glide */
.floating-glide {
  animation: float-glide 8s linear infinite;
}
@keyframes float-glide {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-16px, 18px); }
  50% { transform: translate(20px, -22px); }
  75% { transform: translate(-12px, 14px); }
}

/* 8. Fluid Drift */
.floating-fluid-drift {
  animation: float-fluid-drift 8s linear infinite;
}
@keyframes float-fluid-drift {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-18px, -14px); }
  50% { transform: translate(22px, 16px); }
  75% { transform: translate(-14px, 10px); }
}

/* 9. Gentle Sway */
.floating-gentle-sway {
  animation: float-gentle-sway 8s linear infinite;
}
@keyframes float-gentle-sway {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-12px, 20px); }
  50% { transform: translate(16px, -18px); }
  75% { transform: translate(-10px, 14px); }
}

/* 10. Soft Flow */
.floating-soft-flow {
  animation: float-soft-flow 8s linear infinite;
}
@keyframes float-soft-flow {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(-14px, -16px); }
  50% { transform: translate(18px, 20px); }
  75% { transform: translate(-10px, 12px); }
}