@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.main-container {
  width: 40vw;
  margin: 30px auto;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6;
}
.main-container .blog-heading {
  width: 40vw;
  margin: 50px auto 20px;
  font-size: 45px;
  font-weight: 500;
  line-height: 1.2;
}
.main-container .blog-subheadings {
  margin-top: 50px;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2rem;
}
.main-container p {
  color: #18191b;
  margin-top: 20px;
}
.main-container .blog-screenshot {
  width: 15vw;
  height: 40%;
  margin: 30px auto;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid #333;
}

.blog-breaedcrum {
  display: flex;
  gap: 5px;
  width: 40vw;
  margin: 50px auto;
  font-size: 20px;
}
.blog-breaedcrum .breadcrum-link {
  color: black;
  text-decoration: none;
}
.main-container img {
  margin-top: 30px;
  width: 40vw;
  height: 30%;
  border-radius: 30px;
  object-fit: cover;
}
.main-container .blog-footer {
  margin-top: 50px;
  font-weight: 500;
  line-height: 1.1;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .main-container {
    width: 90%;
    margin: 70px 20px;
  }
  .main-container .blog-heading {
    font-size: 30px;
    margin: 15px 0;
    font-weight: 600;

    width: 90%;
  }

  .blog-breaedcrum {
    display: flex;
    gap: 5px;
    margin: 0px 0px;
    font-size: 12px;
    width: 90%;
  }
  .blog-breaedcrum .breadcrum-link {
    color: black;
  }
  .main-container img {
    width: 100%;
    height: 45vw;
    border-radius: 10px;
    object-fit: cover;
  }
  .main-container .blog-screenshot {
    width: 80vw;
    height: 30%;
    margin: 30px auto;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid #333;
  }
}
